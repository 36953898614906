var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('swiper-slide',[_c('a',{directives:[{name:"smooth-scroll",rawName:"v-smooth-scroll",value:({ duration: 800 }),expression:"{ duration: 800 }"}],attrs:{"href":"#what-i-do"}},[_c('div',{class:( _obj = { slide: true }, _obj[_vm.backgroundImageClass] = true, _obj )},[_c('div',{class:{
          'slide-label': true,
          'slide-label-top': !_vm.swapStyles,
          'slide-label-bottom': _vm.swapStyles,
          'is-uppercase': true
        }},[_vm._v("\n        "+_vm._s(_vm.topLabel)+"\n      ")]),_c('div',{class:{
          'slide-label': true,
          'slide-label-bottom': !_vm.swapStyles,
          'slide-label-top': _vm.swapStyles,
          'is-uppercase': true
        }},[_vm._v("\n        "+_vm._s(_vm.bottomLabel)+"\n      ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }